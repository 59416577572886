:root {
  --atomic-colors-white: rgba(256, 256, 256, 1);
  --atomic-colors-black: rgba(25, 25, 25, 1);
  --atomic-colors-transparent: rgba(0, 0, 0, 0);
  --atomic-colors-initial: initial;
  --atomic-colors-inherit: inherit;
  --atomic-colors-dark: rgba(48, 63, 75, 1.00);

  --atomic-colors-primary: rgba(69, 80, 206, 1.00);
  --atomic-colors-secondary: #2ea2ff;

  --atomic-colors-success: rgba(56, 158, 13, 1.00);
  --atomic-colors-success-1: rgba(221, 244, 217, 1.00);
  --atomic-colors-success-2: rgba(173, 222, 162, 1.00);
  --atomic-colors-success-3: rgba(160, 217, 17, 1.00);
  --atomic-colors-success-4: rgba(91, 140, 0, 1.00);
  --atomic-colors-success-5: rgba(63, 102, 0, 1.00);

  --atomic-colors-error: rgba(229, 57, 53, 1.00);
  --atomic-colors-error-1: rgba(255, 77, 79, 1.00);
  --atomic-colors-error-2: rgba(207, 19, 34, 1.00);
  --atomic-colors-error-3: rgba(168, 7, 26, 1.00);

  --atomic-colors-grey-1: rgba(251, 251, 253, 1);
  --atomic-colors-grey-2: rgba(238, 241, 247, 1);
  --atomic-colors-grey-3: rgba(234, 238, 245, 1);
  --atomic-colors-grey-4: rgba(222, 227, 236, 1);
  --atomic-colors-grey-5: rgba(200, 208, 223, 1);
  --atomic-colors-grey-6: rgba(148, 156, 175, 1);
  --atomic-colors-grey-7: rgba(103, 114, 139, 1);
  --atomic-colors-grey-slate: rgba(87, 95, 101, 1);

  --atomic-colors-focus: #1d98ff;

  /* --atomic-fonts-family: SF Pro Display, Helvetica Neue, Helvetica, Arial, sans-serif; */
  --atomic-fonts-family: Sofia, sans-serif;

  --atomic-fonts-size-h1: 1.5rem;
  --atomic-fonts-size-h2: 1.125rem;
  --atomic-fonts-size-h3: 1rem;
  --atomic-fonts-size-h4: 0.875rem;
  --atomic-fonts-size-p: 0.875rem;
  --atomic-fonts-size: 1rem;
  --atomic-fonts-size-body: 0.875rem;
  --atomic-fonts-size-label: 0.875rem;
  --atomic-fonts-size-placeholder: 1rem;

  --atomic-fonts-weight-bold: 700;
  --atomic-fonts-weight-semibold: 600;
  --atomic-fonts-weight-medium: 500;
  --atomic-fonts-weight-regular: 400;
  --atomic-fonts-weight-thin: 300;
  --atomic-fonts-weight-light: 200;
  --atomic-fonts-weight: 300;

  --atomic-fonts-spacing: 0.0313rem;

  --atomic-fonts-decoration-underline: underline;
  --atomic-fonts-decoration-overline: overline;
  --atomic-fonts-decoration-none: none;
  --atomic-fonts-decoration: inherit;

  --atomic-fonts-style-italic: italic;
  --atomic-fonts-style-normal: normal;
  --atomic-fonts-style: inherit;

  --atomic-spaces-p100: 100%;

  --atomic-spaces-borders-none: 0rem;
  --atomic-spaces-borders-thin: 0.0625rem;
  --atomic-spaces-borders-regular: 0.125rem;

  /**
   * Form elements
   */

  /**
   * <input> element styles
   */
  --atomic-input-border-width-regular: 0.0625rem;
  --atomic-input-border-style-regular: 0.0625rem solid var(--atomic-theme-color-input-border-default);
  --atomic-input-border-radius-regular: 0.1875rem;
  --atomic-input-icon-padding: 0.625rem;
  --atomic-input-padding: 0.4375rem;

  --atomic-avatar-text-tiny: 0.625rem;
  --atomic-avatar-text-small: 0.75rem;
  --atomic-avatar-text-medium: 1rem;
  --atomic-avatar-text-large: 1.375rem;
  --atomic-avatar-text-large: 1.8rem;

  --atomic-spaces-box-height-regular: 3.375rem;

  --atomic-spaces-circle-size-tiny: 0.75rem;
  --atomic-spaces-circle-size-small: 1rem;
  --atomic-spaces-circle-size-medium: 1.375rem;
  --atomic-spaces-circle-size-large: 1.875rem;
  --atomic-theme-space-icon-size-huge: 2.5rem;

  --atomic-spaces-circle-padding-tiny: 0.375rem;
  --atomic-spaces-circle-padding-small: 0.4375rem;
  --atomic-spaces-circle-padding-medium: 0.4375rem;
  --atomic-spaces-circle-padding-large: 0.5rem;
  --atomic-spaces-circle-padding-huge: 0.75rem;

  --atomic-animations-spin: spin 1500ms linear infinite;

  /* Button sizes */
  --atomic-theme-space-button-height: fit-content;
  --atomic-theme-space-button-width: fit-content;
  --atomic-spaces-button-size-tiny-height: 2.5rem;
  --atomic-spaces-button-size-tiny-width: 7.5rem;
  --atomic-spaces-button-size-small-height: 2.5rem;
  --atomic-spaces-button-size-small-width: 11.25rem;
  --atomic-spaces-button-size-medium-height: 3.125rem;
  --atomic-spaces-button-size-medium-width: 14.0625rem;
  --atomic-spaces-button-size-large-height: 5rem;
  --atomic-spaces-button-size-large-width: 22.5rem;

  --atomic-button-font-size-tiny: 0.75vw;
  --atomic-button-font-size-small: 0.8125vw;
  --atomic-button-font-size-medium: 0.875vw;
  --atomic-button-font-size-large: 0.9375vw;

  --atomic-fonts-transform-none: inherit;
  --atomic-fonts-transform-uppercase: uppercase;
  --atomic-fonts-transform-lowercase: lowercase;
  --atomic-fonts-transform-capitalize: capitalize;

  --atomic-theme-text-transform-uppercase: var(--atomic-text-transform-uppercase);
  --atomic-theme-text-transform-lowercase: var(--atomic-text-transform-lowercase);
  --atomic-theme-text-transform-capitalize: var(--atomic-text-transform-capitalize);
  --atomic-theme-text-transform-none: var(--atomic-text-transform-none);

  --atomic-theme-color-black: var(--atomic-colors-dark);
  --atomic-theme-color-white: var(--atomic-colors-white);
  --atomic-theme-color-grey: var(--atomic-colors-grey-3);
  --atomic-theme-color-transparent: var(--atomic-colors-transparent);

  --atomic-theme-color-dark: var(--atomic-colors-dark);
  --atomic-theme-color-dark-text: var(--atomic-theme-color-white);

  --atomic-theme-color-primary: var(--atomic-colors-primary);
  /* --atomic-theme-color-primary-75: var(--atomic-colors-primary-75);
  --atomic-theme-color-primary-50: var(--atomic-colors-primary-50);
  --atomic-theme-color-primary-25: var(--atomic-colors-primary-25); */
  --atomic-theme-color-primary-text: var(--atomic-theme-color-white);

  --atomic-theme-color-success: var(--atomic-colors-success);
  --atomic-theme-color-success-5: var(--atomic-colors-success-5);
  --atomic-theme-color-success-4: var(--atomic-colors-success-4);
  --atomic-theme-color-success-3: var(--atomic-colors-success-3);
  --atomic-theme-color-success-2: var(--atomic-colors-success-2);
  --atomic-theme-color-success-1: var(--atomic-colors-success-1);
  --atomic-theme-color-success-text: var(--atomic-theme-color-white);

  --atomic-theme-color-error: var(--atomic-colors-error);
  --atomic-theme-color-error-3: var(--atomic-colors-error-3);
  --atomic-theme-color-error-2: var(--atomic-colors-error-2);
  --atomic-theme-color-error-1: var(--atomic-colors-error-1);
  --atomic-theme-color-error-text: var(--atomic-theme-color-white);

  --atomic-theme-color-white-text: var(--atomic-colors-dark);
  --atomic-theme-color-grey-text: var(--atomic-colors-grey-7);
  --atomic-theme-color-black-text: var(--atomic-theme-color-white);

  --atomic-theme-color-background: var(--atomic-theme-color-white);
  --atomic-theme-color-background-default: var(--atomic-theme-color-transparent);
  --atomic-theme-color-background-primary: var(--atomic-colors-primary);
  --atomic-theme-color-background-success: var(--atomic-colors-success);
  --atomic-theme-color-background-error: var(--atomic-colors-error);

  --atomic-theme-color-text: var(--atomic-theme-color-black);
  --atomic-theme-color-text-primary: var(--atomic-colors-primary);
  --atomic-theme-color-text-primary-background: var(--atomic-theme-color-white);
  --atomic-theme-color-text-success: var(--atomic-colors-success);
  --atomic-theme-color-text-success-background: var(--atomic-theme-color-white);
  --atomic-theme-color-text-error: var(--atomic-colors-error);
  --atomic-theme-color-text-error-background: var(--atomic-theme-color-white);
  --atomic-theme-color-text-hover: inherit;
  --atomic-theme-color-text-hover-link: var(--atomic-colors-primary);
  --atomic-theme-color-text-disabled: var(--atomic-theme-color-grey);

  --atomic-theme-color-input-border-default: var(--atomic-colors-grey-5);
  --atomic-theme-color-input-border-error: var(--atomic-colors-error);
  --atomic-theme-color-input-border-focus: var(--atomic-colors-focus);
  --atomic-theme-color-input-border-dark: var(--atomic-colors-dark);
  --atomic-theme-color-input-border-success: var(--atomic-colors-success);

  --atomic-theme-color-input-icon-default: var(--atomic-colors-grey-5);
  --atomic-theme-color-input-icon-error: var(--atomic-colors-error);
  --atomic-theme-color-input-icon-focus: var(--atomic-colors-primary);

  --atomic-theme-color-input-placeholder-default: var(--atomic-colors-grey-5);
  --atomic-theme-color-input-placeholder-error: var(--atomic-colors-error);

  --atomic-theme-color-input-dark: var(--atomic-colors-dark);
  --atomic-theme-color-input-error: var(--atomic-colors-error);

  /* TODO: Text usage enumeration after we make below components */
  --atomic-theme-font: var(--atomic-fonts-size);
  --atomic-theme-font-menu: var(--atomic-fonts-size);
  --atomic-theme-font-input: var(--atomic-fonts-size);
  --atomic-theme-font-button: var(--atomic-fonts-size);
  --atomic-theme-font-dropdown: var(--atomic-fonts-size);

  --atomic-theme-space-button-border-none: var(--atomic-spaces-borders-none);
  --atomic-theme-space-button-border-thin: var(--atomic-spaces-borders-thin);
  --atomic-theme-space-button-border-regular: var(--atomic-spaces-borders-regular);

  /* TODO: Icon & Avatar (Circle) Sizing and Colors */
  --atomic-theme-space-icon-border-none: var(--atomic-spaces-borders-none);
  --atomic-theme-space-icon-border-thin: var(--atomic-spaces-borders-thin);
  --atomic-theme-space-icon-border-regular: var(--atomic-spaces-borders-regular);

  --atomic-theme-space-icon-size-tiny: var(--atomic-spaces-circle-size-tiny);
  --atomic-theme-space-icon-size-small: var(--atomic-spaces-circle-size-small);
  --atomic-theme-space-icon-size-medium: var(--atomic-spaces-circle-size-medium);
  --atomic-theme-space-icon-size-large: var(--atomic-spaces-circle-size-large);

  --atomic-theme-space-icon-padding-tiny: var(--atomic-spaces-circle-padding-tiny);
  --atomic-theme-space-icon-padding-small: var(--atomic-spaces-circle-padding-small);
  --atomic-theme-space-icon-padding-medium: var(--atomic-spaces-circle-padding-medium);
  --atomic-theme-space-icon-padding-large: var(--atomic-spaces-circle-padding-large);

  --atomic-theme-space-avatar-size-tiny: calc(var(--atomic-spaces-circle-size-tiny) + 2 * var(--atomic-spaces-circle-padding-tiny));
  --atomic-theme-space-avatar-size-small: calc(var(--atomic-spaces-circle-size-small) + 2 * var(--atomic-spaces-circle-padding-small));
  --atomic-theme-space-avatar-size-medium: calc(var(--atomic-spaces-circle-size-medium) + 2 * var(--atomic-spaces-circle-padding-medium));
  --atomic-theme-space-avatar-size-large: calc(var(--atomic-spaces-circle-size-large) + 2 * var(--atomic-spaces-circle-padding-large));
  --atomic-theme-space-avatar-size-huge: calc(var(--atomic-spaces-circle-size-large) + 2 * var(--atomic-spaces-circle-padding-large));

  --atomic-theme-space-input-border-regular: var(--atomic-spaces-borders-regular);

  --atomic-theme-space-input-border-width-regular: var(--atomic-input-border-width-regular);
  --atomic-theme-space-input-border-radius-regular: var(--atomic-input-border-radius-regular);

  --atomic-theme-space-input-icon-padding: var(--atomic-input-icon-padding);
  --atomic-theme-space-input-padding: var(--atomic-input-padding);

  /* TODO: Input Field Height, State Colors, Icon potential revamp */
  --atomic-theme-space-input-height-regular: var(--atomic-spaces-box-height-regular);

  /* TODO: Buttons Sizing, Colors, Animations */
  --atomic-theme-space-button-size-tiny-height: var(--atomic-spaces-button-size-tiny-height);
  --atomic-theme-space-button-size-tiny-width: var(--atomic-spaces-button-size-tiny-width);
  --atomic-theme-space-button-size-small-height: var(--atomic-spaces-button-size-small-height);
  --atomic-theme-space-button-size-small-width: var(--atomic-spaces-button-size-small-width);
  --atomic-theme-space-button-size-medium-height: var(--atomic-spaces-button-size-medium-height);
  --atomic-theme-space-button-size-medium-width: var(--atomic-spaces-button-size-medium-width);
  --atomic-theme-space-button-size-large-height: var(--atomic-spaces-button-size-large-height);
  --atomic-theme-space-button-size-large-width: var(--atomic-spaces-button-size-large-width);

  --atomic-theme-font-button-size-tiny: var(--atomic-button-font-size-tiny);
  --atomic-theme-font-button-size-small: var(--atomic-button-font-size-small);
  --atomic-theme-font-button-size-medium: var(--atomic-button-font-size-medium);
  --atomic-theme-font-button-size-large: var(--atomic-button-font-size-large);

  /* TODO: Loading Sizing, Colors, Animations */

  /* TODO: Dropdown/Menus Sizing, Colors, Animations */

  /* TODO: Other Animations */
  --atomic-theme-animation-loading-spin: var(--atomic-animations-spin);
}
