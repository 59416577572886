@font-face {
  font-family: 'Sofia';
  src: url('../assets/fonts/Sofia Pro Light.otf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia';
  src: url('../assets/fonts/Sofia Pro Light Italic.otf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia';
  src: url('../assets/fonts/Sofia Pro Light.otf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia';
  src: url('../assets/fonts/Sofia Pro Light Italic.otf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia';
  src: url('../assets/fonts/Sofia Pro Light.otf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia';
  src: url('../assets/fonts/Sofia Pro Light Italic.otf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia';
  src: url('../assets/fonts/Sofia Pro Regular.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia';
  src: url('../assets/fonts/Sofia Pro Italic.otf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia';
  src: url('../assets/fonts/Sofia Pro Medium.otf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SourceCodePro';
  src: url('../assets/fonts/SourceCodePro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia';
  src: url('../assets/fonts/Sofia Pro Medium Italic.otf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia';
  src: url('../assets/fonts/Sofia Pro Semi Bold.otf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia';
  src: url('../assets/fonts/Sofia Pro Semi Bold Italic.otf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia';
  src: url('../assets/fonts/Sofia Pro Semi Bold.otf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia';
  src: url('../assets/fonts/Sofia Pro Semi Bold Italic.otf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia';
  src: url('../assets/fonts/Sofia Pro Semi Bold.otf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia';
  src: url('../assets/fonts/Sofia Pro Semi Bold Italic.otf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia';
  src: url('../assets/fonts/Sofia Pro Semi Bold.otf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia';
  src: url('../assets/fonts/Sofia Pro Semi Bold Italic.otf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

html,
body,
#root {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overscroll-behavior: none;
  scroll-behavior: smooth;
  font-family: Sofia, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#modal-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 2.5rem;
  position: fixed;
  visibility: hidden;
  z-index: 1000;
}

#modal-root * {
  visibility: visible;
}

code, pre, code *, pre * {
  font-family: 'SourceCodePro', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-1.25rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-1.25rem);
  }
}
