@import-normalize;

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Sofia, 'SF Pro Display';
}

input {
  background-color: transparent;
}

a {
  text-decoration: unset;
  color: unset;
}

a:hover a:focus {
  text-decoration: unset;
  color: unset;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 18px;
  background-color: #EEF1F7;
}

::-webkit-scrollbar-track {
  margin: 2px;
}

::-webkit-scrollbar-thumb {
  background: #C8D0DF;
  border-radius: 11px;
}



